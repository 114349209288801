import Image from 'next/image'

const RowsSection = ({
	title,
	titleAlignment,
	blocks,
}: {
	title?: string
	titleAlignment?: 'self-start' | 'self-center' | 'self-end'
	blocks: {
		title: string
		description: JSX.Element
		img?: { src: string; alt: string }
		video?: string
	}[]
}) => {
	return (
		<section className='max-w-6xl mx-auto px-4 sm:px-6 mb-20 flex flex-col items-center relative'>
			{title && (
				<h2
					className={`font-display text-4xl font-bold  center ${
						titleAlignment || 'self-center'
					}`}>
					{title}
				</h2>
			)}

			<div
				className={`flex flex-col w-full gap-y-20 justify-center relative ${
					title ? 'mt-10' : ''
				}`}>
				{blocks.map((block, index) => {
					return (
						<div
							key={block.title}
							className={`flex relative flex-nowrap items-start flex-col justify-between md:flex-row`}>
							<div
								className={`w-full md:w-1/2 h-72 lg:h-80 relative flex-shrink-0 ${
									index % 2 === 1 ? 'md:order-2' : 'md:order-1'
								}`}>
								{block.img && (
									<Image
										fill
										className='object-cover h-full w-full overflow-hidden'
										src={block.img.src}
										alt={block.img.alt}></Image>
								)}
								{block.video && (
									<iframe
										width={'100%'}
										height={'100%'}
										src={block.video}
										title='YouTube video player'
										frameBorder={0}
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
										className='object-cover h-full w-full overflow-hidden'
									/>
								)}
							</div>

							<div
								className={`mt-6 md:mt-6 text-center ${
									index % 2 === 1
										? 'md:text-right md:mr-16 md:order-1'
										: 'md:text-left md:ml-16 md:order-2'
								}`}>
								<h3 className='font-display text-[28px] font-bold  mb-2'>
									{block.title}
								</h3>
								<p className='font-medium text-gray-600'>{block.description}</p>
							</div>
						</div>
					)
				})}
			</div>
		</section>
	)
}

export default RowsSection
