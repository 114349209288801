const ColumnsSection = ({
	title,
	titleAlignment,
	blocks,
	bottomMargin,
	id,
}: {
	title: string
	titleAlignment?: 'text-left' | 'text-center' | 'text-right'
	blocks: { title: string; description: JSX.Element; image: JSX.Element }[]
	bottomMargin?: string
	id?: string
}) => {
	return (
		<section
			id={id}
			className={`max-w-6xl mx-auto px-4 sm:px-6 ${
				bottomMargin || 'mb-24'
			} flex flex-col items-center relative`}>
			{/*<DescriptionSlider></DescriptionSlider>*/}
			<h2 className={`font-display text-4xl font-bold  ${titleAlignment || 'text-center'}`}>
				{title}
			</h2>
			<div className='flex flex-wrap sm:grid sm:grid-cols-3 text-center w-full gap-10 mt-10 justify-center'>
				{blocks.map((block) => {
					return (
						<div key={block.title} className='flex flex-col items-center gap-y-2'>
							{block.image}
							<h3 className='font-display text-[28px] font-bold  center'>
								{block.title}
							</h3>
							<p className='font-medium text-gray-600'>{block.description}</p>
						</div>
					)
				})}
			</div>
		</section>
	)
}

export default ColumnsSection
