import { memo } from 'react'

const Modal = ({
	clicked,
	isActive,
	message,
	children,
	title,
	firstButtonClicked,
	firstButtonTitle,
	secondButtonClicked,
	secondButtonTitle,
	close,
}: {
	clicked?: () => void
	isActive?: boolean
	message?: string
	children?: any
	title?: string
	firstButtonClicked?: () => void
	firstButtonTitle?: string
	secondButtonClicked?: () => void
	secondButtonTitle?: string
	close?: () => void
}) => {
	const renderButtons = () => {
		//Two Button Modal
		if (secondButtonClicked && secondButtonTitle) {
			return (
				<div className='modal-action flex flex-row gap-x-4 mx-auto justify-center'>
					<button
						className='btn btn-ghost rounded-3xl h-10 text-lg w-28 drop-shadow-md bg-gray-100 border-aquablue border-2 border-solid text-darksky text-center'
						onClick={firstButtonClicked}>
						{firstButtonTitle}
					</button>
					<button
						className='btn btn-accent rounded-3xl h-10 text-lg w-28 drop-shadow-md text-center'
						onClick={secondButtonClicked}>
						{secondButtonTitle}
					</button>
				</div>
			)
		}
		//One Button Modal
		else {
			return (
				<div className='modal-action justify-center'>
					<button
						className='btn btn-accent rounded-3xl h-10 text-lg w-fit mx-auto drop-shadow-md'
						onClick={clicked}>
						{firstButtonTitle || 'OKAY'}
					</button>
				</div>
			)
		}
	}

	return (
		<div className={`modal ${isActive ? 'modal-open' : ''}`}>
			<div className='modal-box max-w-sm mx-7 my-auto rounded-2xl text-center'>
				{close && (
					<button
						onClick={close}
						aria-label='Close'
						className='absolute right-1 top-1 text-4xl w-8 h-8 font-bold leading-6 pb-4 bg-gray-200 bg-opacity-75 shadow-sm rounded-full text-gray-400 hover:text-gray-500 hover:bg-gray-300'>
						×
					</button>
				)}
				<h4 className='text-2xl font-medium pb-5 text-center'>{title}</h4>
				{typeof message !== 'undefined' && <p className='text-center pb-5'>{message}</p>}
				{children}
				{renderButtons()}
			</div>
		</div>
	)
}

export default memo(Modal)
